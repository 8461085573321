// IIFE called when script is loaded
// Immediately invoked function expression
(function initModule() {

    const CSS_CLASS_MODAL = 'js-modal';
    const CSS_CLASS_MODAL_OPEN_BUTTON = 'js-modal-btn';

    const modals = [ ...document.querySelectorAll( `.${CSS_CLASS_MODAL}` ) ];

    if ( !modals ) return;

    modals.forEach( ( modal ) => {
        const modalOpenBtn = modal.querySelector( `.${CSS_CLASS_MODAL_OPEN_BUTTON}` );

        modalOpenBtn.addEventListener( 'click', function() {
            const targetModal = modal.querySelector( `#${modalOpenBtn.dataset.target}` );

            /*eslint-disable no-undef*/
            new Modal( { el: targetModal } ).show();
            /*eslint-enable no-undef*/
            targetModal.classList.add( 'in' );
        } );

    } );
})();
